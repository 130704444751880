import api from '@/services/api/api';

export const usePopularArticles = (params = {}) => {
  const popularArticles = useQuery(
    ['popularArticles'],
    async () => {
      const { data } = await api.articles.getPopularArticles({
        days: params.days || 90,
        count: params.count || 30,
        exclude_ids: params.exclude_ids || [],
        order_by_top: Number(params.order_by_top) || undefined,
      });
      return data;
    },
    { staleTime: Infinity, select: ({ data }) => data },
  );

  onServerPrefetch(async () => {
    await popularArticles.suspense();
  });

  return {
    popularArticles,
  };
};
